import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import SVG from "./SVG"
import Subscribe from "./Subscribe"
import "./GlobalFooter.scss"

export default (props) => (
  /**
   * Gets all the data for the footer from AgilityCMS
   */

  <StaticQuery
    query={graphql`
      query GlobalFooterQuery {
        allAgilitySitemapNode {
          nodes {
            languageCode
            path
            menuText
            pageID
            visible {
              menu
            }
          }
        }
        agilityGlobalFooter(
          properties: { referenceName: { eq: "globalfooter" } }
        ) {
          customFields {
            contactNumberOne
            contactNumberTwo
            featuredEmailAddress
            subscribeFormLabel
            subscribeSubmitButtonText
            subscribeTitle
            facebookUrl {
              href
              target
              text
            }
            instagramUrl {
              href
              target
              text
            }
            linkedInUrl {
              href
              target
              text
            }
            youtubeUrl {
              href
              target
              text
            }
          }
        }
      }
    `}
    render={(queryData) => {
      const viewModel = {
        customFields: queryData.agilityGlobalFooter.customFields,
        route: props.route,
        menuLinks: queryData.allAgilitySitemapNode.nodes.filter(
          (sitemapNode) => {
            let isTopLevelPage = sitemapNode.path.split("/").length === 2
            const isThisLanguage =
              sitemapNode.languageCode === props.languageCode
            if (sitemapNode.path === "/home") sitemapNode.path = "/"
            if (props.isMultiLanguage) {
              isTopLevelPage = sitemapNode.path.split("/").length === 3
            }
            return isThisLanguage && isTopLevelPage && sitemapNode.visible.menu
          }
        ),
      }
      return <GlobalFooter {...viewModel} />
    }}
  />
)

/**
 * Component to render the footer.
 * @function GlobalFooter
 */
const GlobalFooter = (props) => {
  const customFields = props.customFields
  const subscribeFormData = {
    label: customFields.subscribeFormLabel,
    btnTxt: customFields.subscribeSubmitButtonText,
  }

  /**
   * Loops trough the links and returns an array of JSX elements.
   * @function renderLinks
   */

  const renderLinks = () => {
    const links = []

    props.menuLinks.forEach((node) => {
      links.push(
        <li
          className={`nav-links__item ${
            node.path === props.route.pathname ? "active" : ""
          }`}
          key={node.pageID}
        >
          <Link to={node.path}>{node.menuText}</Link>
        </li>
      )
    })

    links.push(
      <li
        key={"sitemap"}
        className={`nav-links__item ${
          "/sitemap" === props.route.pathname ? "active" : ""
        }`}
      >
        <Link to={"/sitemap"}>Sitemap</Link>  
      </li>
    )
    links.push(
      <li
        key={"privacy-policy"}
        className={`nav-links__item ${
          "/privacy-policy" === props.route.pathname ? "active" : ""
        }`}
      >
        <Link to={"/privacy-policy"}>Privacy Policy</Link>  
      </li>
    )
    return links
  }

  /**
   * Footer  JS rendering.
   */

  return (
    <footer>
      <div className="container-fluid">
        <div className="row justify-content-sm-center">
          <div className="col-12 col-md-6 d-flex flex-column mb-sm-5">
            <div className="d-flex w-100 justify-content-center justify-content-sm-start order-1 order-sm-3">
              <SVG
                fill={"#FFF"}
                name={"logo-and-slogan"}
                width={335}
                height={28}
              ></SVG>
            </div>
            <p className="subscribe-title order-sm-1 order-2 mb-sm-4">
              {customFields.subscribeTitle}
            </p>
            <div className="subscribe-wrapper order-3 order-sm-2 mb-sm-4">
              <Subscribe {...subscribeFormData} />
            </div>
          </div>
          <div className="col-12 col-md-6 d-flex flex-column justify-content-sm-end align-items-sm-end mb-sm-5">
            <p className="info-details">{customFields.featuredEmailAddress}</p>
            <p className="info-details">{customFields.contactNumberOne}</p>
            <p className="info-details">{customFields.contactNumberTwo}</p>
            <nav className="d-sm-none">
              <ul className="mobile-nav">
                <li
                  key={"sitemap"}
                  className={`nav-links__item mb-3 ${
                    "/sitemap" === props.route.pathname ? "active" : ""
                  }`}
                >
                  <Link to={"/sitemap"}>Sitemap</Link>  
                </li>
                <li
                  key={"privacy-policy"}
                  className={`nav-links__item ${
                    "/privacy-policy" === props.route.pathname ? "active" : ""
                  }`}
                >
                  <Link to={"/privacy-policy"}>Privacy Policy</Link>  
                </li>
              </ul>
            </nav>
            <ul className="social-links mt-sm-3">
              <li className="social-links__item">
                <a
                  href={customFields.facebookUrl.href}
                  rel="noopener"
                  target={customFields.facebookUrl.target}
                  aria-label="Pixel506 Facebook page (Opens a new Window)."
                >
                  <SVG
                    fill={"#FFF"}
                    name={"facebook"}
                    width={40}
                    height={40}
                  ></SVG>
                </a>
              </li>
              <li className="social-links__item">
                <a
                  href={customFields.instagramUrl.href}
                  rel="noopener"
                  target={customFields.instagramUrl.target}
                  aria-label="Pixel506 Instagram page (Opens a new Window)."
                >
                  <SVG
                    fill={"#FFF"}
                    name={"instagram"}
                    width={40}
                    height={40}
                  ></SVG>
                </a>
              </li>
              <li className="social-links__item">
                <a
                  href={customFields.youtubeUrl.href}
                  rel="noopener"
                  target={customFields.youtubeUrl.target}
                  aria-label="Pixel506 Youtube page (Opens a new Window)."
                >
                  <SVG
                    fill={"#FFF"}
                    name={"youtube"}
                    width={40}
                    height={40}
                  ></SVG>
                </a>
              </li>
              <li className="social-links__item">
                <a
                  href={customFields.linkedInUrl.href}
                  rel="noopener"
                  target={customFields.linkedInUrl.target}
                  aria-label="Pixel506 LinkedIn page (Opens a new Window)."
                >
                  <SVG
                    fill={"#FFF"}
                    name={"linked-in"}
                    width={40}
                    height={40}
                  ></SVG>
                </a>
              </li>
            </ul>
          </div>
          <nav
            aria-label="Footer navigation."
            className="d-none d-md-flex col-lg-11 mb-sm-5"
          >
            <ul className="nav-links w-100 d-flex justify-content-between align-items-center">
              {renderLinks()}
            </ul>
          </nav>
          <div className="col-12 col-sm-12">
            <p className="copyright">
              &#169; PIXEL506, LLC. ALL RIGHTS RESERVED
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}
