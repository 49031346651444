import SVG from "./SVG"
import React, { useEffect, useState, useRef } from "react"
import "./GlobalHeader.scss"
import Subscribe from "./Subscribe"
import { Link, graphql, StaticQuery } from "gatsby"
import PXlogo from "../img/PX-now-10P2.gif"
import TopBar from "./TopBar"
export default props => (
  /**
   * Gets all the data for the footer from AgilityCMS
   */

  <StaticQuery
    query={graphql`
      query GlobalHeaderQuery {
        agilityGlobalHeader(
          properties: { referenceName: { eq: "globalheader" } }
        ) {
          customFields {
            buttonText
            inputLabel
            inputPlaceholder
            siteName
          }
        }
        allAgilitySitemapNode {
          nodes {
            languageCode
            path
            menuText
            pageID
            visible {
              menu
            }
          }
        }
      }
    `}
    render={queryData => {
      const services = queryData.allAgilitySitemapNode.nodes.filter(
        sitemapNode => {
          const isServices = sitemapNode.path.includes("/services")
          const isThisLanguage = sitemapNode.languageCode === props.languageCode
          let isTopLevelPage = sitemapNode.path.split("/").length === 2
          if (props.isMultiLanguage) {
            isTopLevelPage = sitemapNode.path.split("/").length === 3
          }
          return (
            isServices &&
            isThisLanguage &&
            isTopLevelPage &&
            sitemapNode.visible.menu
          )
        }
      )[0]

      const secondlevelServices = queryData.allAgilitySitemapNode.nodes.filter(
        sitemapNode => {
          const isServices = sitemapNode.path.includes("/services")
          const isThisLanguage = sitemapNode.languageCode === props.languageCode
          let isSecondLevel = sitemapNode.path.split("/").length === 3
          if (props.isMultiLanguage) {
            isSecondLevel = sitemapNode.path.split("/").length === 4
          }
          return (
            isServices &&
            isThisLanguage &&
            isSecondLevel &&
            sitemapNode.visible.menu
          )
        }
      )

      let thirdlevelServices = queryData.allAgilitySitemapNode.nodes.filter(
        sitemapNode => {
          const isServices = sitemapNode.path.includes("/services")
          const isThisLanguage = sitemapNode.languageCode === props.languageCode
          let isThirdLevel = sitemapNode.path.split("/").length === 4
          if (props.isMultiLanguage) {
            isThirdLevel = sitemapNode.path.split("/").length === 5
          }
          return (
            isServices &&
            isThisLanguage &&
            isThirdLevel &&
            sitemapNode.visible.menu
          )
        }
      )

      thirdlevelServices = thirdlevelServices.sort(
        (a, b) => a.menuText > b.menuText
      )

      secondlevelServices.forEach((service, index) => {
        const serviceCategory = service.path.split("/")[2]
        secondlevelServices[index].childNodes = []
        thirdlevelServices.forEach(child => {
          const childServiceCategory = child.path.split("/")[2]
          if (childServiceCategory === serviceCategory) {
            secondlevelServices[index].childNodes.push(child)
          }
        })
      })

      services.childNodes = secondlevelServices

      const viewModel = {
        variation: props.variation,
        item: queryData.agilityGlobalHeader,
        route: props.route,
        menuLinks: queryData.allAgilitySitemapNode.nodes.filter(sitemapNode => {
          let isTopLevelPage = sitemapNode.path.split("/").length === 2
          const isServices = sitemapNode.path.includes("/services")
          const isThisLanguage = sitemapNode.languageCode === props.languageCode
          if (props.isMultiLanguage) {
            isTopLevelPage = sitemapNode.path.split("/").length === 3
          }
          //only return nodes from this language and top level links only
          return (
            !isServices &&
            isThisLanguage &&
            isTopLevelPage &&
            sitemapNode.visible.menu
          )
        }),
        services: services,
        fullpage: props.fullpage,
      }
      return <GlobalHeader {...viewModel} />
    }}
  />
)

/**
 * Component to render the header.
 * @function GlobalHeader
 */
const GlobalHeader = props => {
  const [showing, setShowing] = useState(false)
  const [showFixedNav, setShowFixedNav] = useState(false)
  const [showHamburguerDesktop, setShowHamburguerDesktop] = useState(false)
  const [lastScrollPos, setScrollPos] = useState(
    typeof window !== `undefined` ? window.scrollY || window.pageYOffset : 0
  )

  const [closeTopBar, setCloseTopBar] = useState(false)

  const fixedNav = useRef()

  const toggleNav = () => {
    setShowing(!showing)
  }

  /**
   * Handles the scroll of the page to take an action depending on how far has the user scrolled.
   * @function handleScroll
   */
  const handleScroll = () => {
    const scrollPos =
      typeof window !== `undefined` ? window.scrollY || window.pageYOffset : 0
    const headerStyles =
      typeof window !== `undefined`
        ? window.getComputedStyle(fixedNav.current)
        : { height: 0 }
    const headerHeight = parseInt(headerStyles.height)

    setShowFixedNav(scrollPos > headerHeight && scrollPos < lastScrollPos)
    setShowHamburguerDesktop(scrollPos > lastScrollPos)
    setScrollPos(scrollPos)
  }

  /**
   * useEffect hook that changes the state of the menu.
   */
  useEffect(() => {
    const body = typeof document !== `undefined` ? document.body : null
    if (!body) return

    if (showing) {
      body.classList.add("menu-open")
    } else {
      body.classList.remove("menu-open")
    }
  }, [showing])

  /**
   * useEffect hook that changes the state of the fixed and expanded menu depending on the scroll.
   */
  useEffect(() => {
    const body = typeof document !== `undefined` ? document.body : null
    if (!body) return

    if (showFixedNav) {
      body.classList.add("scroll-back")
    } else {
      body.classList.remove("scroll-back")
    }
  }, [showFixedNav])

  /**
   * useEffect hook to add and remove the handleScroll event handler to the window global object.
   */
  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.addEventListener("scroll", handleScroll)
    }

    return () => {
      if (typeof window !== `undefined`) {
        window.removeEventListener("scroll", handleScroll)
      }
    }
  })

  /**
   * useEffect hook to detect the direction the user is scrolling to so the menu changed from fixed to full and viceversa.
   */
  useEffect(() => {
    if (props.fullpage) {
      const { direction } = props.fullpage
      const { isFirst } = props.fullpage

      if (direction === "down") {
        setShowHamburguerDesktop(true)
        setShowFixedNav(false)
      }

      if (direction === "up") {
        setShowHamburguerDesktop(false)
        if (isFirst) {
          setShowFixedNav(false)
        }
        if (!isFirst) {
          setShowFixedNav(true)
        }
      }
    }
  })

  /**
   * Renders the items of the menu depending on the query from agility.
   * @function MenuItems
   */
  const MenuItems = ({ tabbable }) =>
    props.menuLinks.map((link, index) => (
      <li
        className={`menu-item 
        ${props.route.pathname.includes(link.path) ? "active" : ""}`}
        key={index}
      >
        <Link to={link.path} tabIndex={tabbable ? "1" : "0"}>
          {link.menuText}
        </Link>
      </li>
    ))

  /**
   * Sets the service for the menu links.
   * @function ServiceItem
   */
  const ServiceItem = ({ tabbable }) => (
    <li
      className={`menu-item menu-item__services
      ${props.route.pathname.includes(props.services.path) ? "active" : ""}`}
    >
      <Link
        className="main-link"
        to={props.services.path}
        tabIndex={tabbable ? "1" : "0"}
      >
        {props.services.menuText}
      </Link>
      <ul className="secondary-link-wrapper d-md-none">
        {props.services.childNodes.map((secondLvl, key) => {
          return (
            <li
              key={key}
              className={`${props.route.pathname.includes(secondLvl.path) ? "active" : ""
                }`}
            >
              <Link
                className="secondary-link"
                to={secondLvl.path}
                tabIndex={tabbable ? "1" : "0"}
              >
                {secondLvl.menuText}
              </Link>
            </li>
          )
        })}
      </ul>
    </li>
  )

  /**
   * Sets the service for the dropdown links.
   * @function ServiceDropDown
   */
  const ServiceDropDown = ({ tabbable }) => {
    return (
      <li
        className={`menu-item dropdown
        ${props.route.pathname.includes(props.services.path) ? "active" : ""}`}
      >
        <span tabIndex={tabbable ? "1" : "0"}>{props.services.menuText}</span>
        <ul className="dropdown-menu">
          {props.services.childNodes.map((category, index) => (
            <li
              className={`categoty col-3
              ${props.route.pathname.includes(category.path) ? "active" : ""}`}
              key={index}
            >
              <Link to={category.path} tabIndex={tabbable ? "1" : "0"}>
                {category.menuText}
              </Link>
              <ul className="category-list">
                {category.childNodes.map((service, index) => (
                  <li
                    className={`service 
                    ${props.route.pathname.includes(service.path)
                        ? "active"
                        : ""
                      }`}
                    key={index}
                  >
                    <Link to={service.path} tabIndex={tabbable ? "1" : "0"}>
                      {service.menuText}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </li>
    )
  }

  /**
   * Sets the services for the hamburguer menu links.
   * @function ServicesHamburguerMenu
   */
  const ServicesHamburguerMenu = ({ tabbable }) => {
    return (
      <div className="services-hamburguer-section">
        {props.services.childNodes.map((category, index) => (
          <ul className="category-wrapper" key={index}>
            <li
              className={`category
              ${props.route.pathname.includes(category.path) ? "active" : ""}`}
            >
              <Link
                className="category-title"
                to={category.path}
                tabIndex={tabbable ? "1" : "0"}
              >
                {category.menuText}
              </Link>
            </li>
            <li>
              <ul className="category-list">
                {category.childNodes.map((service, index) => (
                  <li
                    className={`service 
                    ${props.route.pathname.includes(service.path)
                        ? "active"
                        : ""
                      }`}
                    key={index}
                  >
                    <Link
                      className="service-link"
                      to={service.path}
                      tabIndex={tabbable ? "1" : "0"}
                    >
                      {service.menuText}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        ))}
      </div>
    )
  }

  /**
   * Renders the logo using the SVG component.
   * @function Logo
   */
  const Logo = () => {
    return (
      <Link
        to="/"
        className="logo"
        aria-label="Pixel506 Logo, click to navigate to the Homepage"
      >
        {/* <SVG name="pixel-logo" width="120" height="30"></SVG> */}
        {/* <SVG name="pearls-pixel-logo" width="150" height="80"></SVG> */}
        <img src={PXlogo} alt="PX-now 10Pearls" width="300" height="80" />
      </Link>
    )
  }

  const handleScrollToForm = () => {
    if (typeof window === `undefined`) return
    if (document.querySelector(".form-section")) {
      window.scrollTo({
        top: document.querySelector(".form-section").offsetTop,
        behavior: "smooth",
      })
    }
  }

  

  /**
   * Renders the footer.
   */
  return (
    <header className={`px-header ${props.variation}`}>
      <nav
        aria-hidden={showFixedNav}
        aria-label="Fixed to viewport when scrolling upwards Navigation menu."
        className={`px-header-nav fixed ${props.fullpage ? "fullpage" : ""} ${showFixedNav ? "showing" : ""
          }`}
        ref={fixedNav}
      >
        <div className={`px-header-nav-group `}>
          <Logo />
        </div>
        <div className="px-header-nav-group">
          <ul className="menu d-none d-xl-flex">
            <ServiceDropDown tabbable={showFixedNav} />
            <MenuItems tabbable={showFixedNav} />
          </ul>
        </div>
        <div className="px-header-nav-group">
          <button
            type="button"
            className="btn-solid-pink nav-button d-none d-md-block "
            onClick={handleScrollToForm}
          >
            Contact Us
          </button>
        </div>
      </nav>
      <TopBar closeTopBar={closeTopBar} setCloseTopBar={setCloseTopBar} />
      <nav
        aria-label="Top document navigation menu."
        className={`px-header-nav`}
      >
        <div
          className={`px-header-nav-group ${props.fullpage ? "fp-logo" : ""} ${props.fullpage && !props.fullpage.isFirst ? "hide" : ""
            }`}
        >
          <Logo />
        </div>
        <div
          className={`px-header-nav-group ${props.fullpage ? "fullpage-nav" : ""
            }
          ${props.fullpage && props.fullpage.isFirst ? "first" : ""}
          `}
        >
          <ul className="menu d-none d-xl-flex">
            <ServiceDropDown tabbable={!(showing || showFixedNav)} />
            <MenuItems tabbable={!(showing || showFixedNav)} />
          </ul>
        </div>
        <div className="px-header-nav-group">
          <button
            type="button"
            className="btn-solid-pink nav-button d-none d-md-block "
            onClick={handleScrollToForm}
          >
            Contact Us
          </button>
        </div>
      </nav>

      <button
        type="button"
        className={`menu-toggle ${showing ? "active" : ""} ${showHamburguerDesktop && !showing ? "down" : ""
          } ${showing ? "down" : ""} ${props.fullpage ? "fullpage" : ""} ${!closeTopBar ? "top-70" : ""}`}
        aria-label="Open menu button"
        onClick={toggleNav}
      >
        <span></span>
      </button>

      <nav
        aria-hidden={showing}
        aria-label="Overlay menu navigation."
        className={`menu-collapse ${props.fullpage ? "fullpage" : ""} ${showing ? "visible" : ""
          }`}
      >
        <div className="menu-collapse-wrapper">
          <p className="menu-title">Menu</p>
          <ul className="menu">
            <li className="menu-item">
              <Link to="/">Home</Link>
            </li>
            <ServiceItem tabbable={showing} />
            <MenuItems tabbable={showing} />
          </ul>
          <Subscribe
            label={props.item.customFields.inputLabel}
            btnTxt={props.item.customFields.buttonText}
          ></Subscribe>
        </div>
        <div className="subscribe-wrapper">
          <ServicesHamburguerMenu tabbable={showing} />
          <Subscribe
            label={props.item.customFields.inputLabel}
            btnTxt={props.item.customFields.buttonText}
            placeholder={props.item.customFields.inputPlaceholder}
          ></Subscribe>
        </div>
      </nav>
    </header>
  )
}
