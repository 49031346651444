import React, { useState, useRef } from "react"
import StudioLogo from "../img/10P-Studio.svg"
import "./TopBar.scss"

const TopBar = ({ closeTopBar, setCloseTopBar }) => {
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);


	return (
		<> {!closeTopBar &&
			<div className='top-bar d-flex flex-column flex-md-row justify-content-between'>
				<div className="group d-none d-lg-flex align-items-center">
					<span className='text'>
						SAME AMAZING AGENCY NOW
					</span>
					<img className='ml-4' src={StudioLogo} alt='10P Studio' />
				</div>
				<div className="group d-flex align-items-center justify-content-between">
					<button className="open-btn" onClick={handleOpen}>
						<svg className='play-icon mr-2' viewBox="-4.8 -4.8 57.60 57.60" xmlns="http://www.w3.org/2000/svg" fill="#000000" stroke="#000000" stroke-width="0.00048000000000000007"><g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(5.759999999999998,5.759999999999998), scale(0.76)"><rect x="-4.8" y="-4.8" width="57.60" height="57.60" rx="28.8" fill="#ffffff" strokewidth="0"></rect></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>play-circle-solid</title> <g id="Layer_2" data-name="Layer 2"> <g id="invisible_box" data-name="invisible box"> <rect width="48" height="48" fill="none"></rect> </g> <g id="icons_Q2" data-name="icons Q2"> <path d="M24,2A22,22,0,1,0,46,24,21.9,21.9,0,0,0,24,2ZM34.6,24.7,18.1,34.8c-.6.4-1.1.1-1.1-.6V13.8c0-.7.5-1,1.1-.6L34.6,23.3A.8.8,0,0,1,34.6,24.7Z"></path> </g> </g> </g></svg>
						<span className='watch-video link-underline'>Watch Our Video</span>
					</button>
					{/* This should be uncommented when 10pearls.studio is launched*/}
					{/* <a href="https://10pearls.studio" target='' rel='noopener' aria-label="Watch Our Vieo">
						<svg className='mx-3' xmlns="http://www.w3.org/2000/svg" width="11" height="33" viewBox="0 0 11 33" fill="none">
							<path d="M9.40893 0H10.704L1.29512 32.4094H0L9.40893 0Z" fill="#1D2327" />
						</svg>
						<span className='link-underline'>Visit 10Pearls.studio</span>
					</a> */}
					<svg className='mx-3 d-none d-lg-flex' xmlns="http://www.w3.org/2000/svg" width="11" height="33" viewBox="0 0 11 33" fill="none">
						<path d="M9.40893 0H10.704L1.29512 32.4094H0L9.40893 0Z" fill="#1D2327" />
					</svg>
					<button className="close-btn d-flex" onClick={() => setCloseTopBar(true)}>
						<span className='link-underline d-none d-md-flex'>Close</span>
						<svg className='close-x' xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
							<path d="M1 1L21 21" stroke="black" stroke-width="0.740741" stroke-linecap="round" />
							<path d="M1 21L21 1" stroke="black" stroke-width="0.740741" stroke-linecap="round" />
						</svg>
					</button>
				</div>
			</div>
		}

			{open && (
				<div className="custom-modal">
					<div className="modal-content">
						<div className="d-flex justify-content-end my-1 my-md-2">
							<button className="close-modal-btn" onClick={handleClose}>
								<svg className='close-x' xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
									<path d="M1 1L21 21" stroke="black" stroke-width="0.740741" stroke-linecap="round" />
									<path d="M1 21L21 1" stroke="black" stroke-width="0.740741" stroke-linecap="round" />
								</svg>
							</button>
						</div>
						<iframe
							width="800"
							height="448"
							src="https://www.youtube.com/embed/LNGQ-ltZ3Jk?playlist=LNGQ-ltZ3Jk&rel=0&autoplay=1"
							title="YouTube video player"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
						></iframe>

					</div>
				</div>
			)}

		</>
	)
}

export default TopBar